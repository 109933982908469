<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات العميل
      </h4>
      <div class="alert-body">
        لم يتم العثور على العميل مع المعرف المقدّم. راجع
        <b-link
          class="alert-link"
          :to="{ name: 'clients-list' }"
        >
          قائمة العملاء
        </b-link>
      </div>
    </b-alert>

    <b-tabs
      v-if="clientData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("ملف العميل") }}</span>
        </template>
        <company-client-stats-card
          v-if="clientData.type !== 'individuals'"
          :client-data="clientData"
        />
        <individual-client-stats-card
          v-else
          :client-data="clientData"
        />
        <b-card>
          <!-- Signature Image -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <!-- Use a v-if to check if clientData.signature exists -->
                <b-img
                  v-if="clientData && clientData.logo"
                  ref="previewEl"
                  rounded
                  :src="clientData.logo"
                  height="80"
                />
                <!-- If clientData.signature doesn't exist or is null, show a placeholder image -->
                <b-img
                  v-if="!clientData.logo"
                  ref="previewEl"
                  rounded
                  src="/logo-placeholder.png"
                  height="80"
                />
              </b-link>
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                اختر صورة العلامة التجارية
              </b-button>
              <b-form-file
                ref="refInputEl"
                v-model="logoFile"
                name="image"
                accept="image/*"
                :hidden="true"
                plain
                @input="inputImageRenderer"
              />
              <!-- reset -->
              <b-button
                v-if="logoFile"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="updateClientLogo"
              >
                تحميل
              </b-button>
              <!--/ reset -->
              <b-card-text>
                الصيغة المسموح بها: JPG، GIF، أو PNG. الحد الأقصى 2 ميجابايت
              </b-card-text>
            </b-media-body>
          </b-media>
          <!--/ Signature Image -->
        </b-card>
        <b-card>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              بيانات العميل
            </h4>
          </div>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <client-edit-tab-information
            :client-data="clientData"
            class="mt-2 pt-75"
            @reset-client-data="resetClientData"
          />
        </b-card>
        <b-card v-if="clientData.type !== 'individuals'">
          <!-- Header: Address-->
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              العنوان الوطني للعميل
            </h4>
          </div>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <address-account-info
            :address-data="addressData"
            class="mt-2 pt-75"
            @reset-address-data="resetAddressData"
          />
        </b-card>
        <b-card v-if="clientData.type !== 'individuals'">
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <contact-account-info
            :contact-data="contactData"
            class="mt-2 pt-75"
            @reset-contact-data="resetContactData"
          />
        </b-card>
      </b-tab>
      <!-- Tab: Contract -->
      <b-tab v-if="clientData.type !== 'individuals'">
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("العقود") }}</span>
        </template>
        <contracts-list class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Quotation -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="BriefcaseIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("عروض الأسعار") }}</span>
        </template>
        <quotations-list class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Evaluation Request -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("التّقييمات") }}</span>
        </template>
        <evaluation-requests-list class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive';
import { serialize } from 'object-to-formdata';
import {
  BInput,
  BFormFile,
  BCardText,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BTab,
  BTabs,
  BAlert,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import router from '@/router';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { useInputImageRenderer } from '@/@core/comp-functions/forms/form-utils';
import ContactAccountInfo from './company/account-tab/ContactAccountInfo';
import AddressAccountInfo from './company/account-tab/AddressAccountInfo';
import EvaluationRequestsList from './evaluation-requests-tab/EvaluationRequestsList';
import QuotationsList from './quotations-tab/QuotationsList';
import ContractsList from './company/contracts-tab/ContractsList';
import IndividualClientStatsCard from './individual/account-tab/IndividualClientStatsCard.vue';
import CompanyClientStatsCard from './company/account-tab/CompanyClientStatsCard';

import ClientEditTabInformation from '../client-add/ClientEditTabInformation.vue';
import clientStore from '../clientStore';

export default {
  components: {
    BFormFile,
    BMediaAside,
    BMediaBody,
    BMedia,
    BLink,
    BImg,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BTab,
    BTabs,
    BAlert,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    IndividualClientStatsCard,
    CompanyClientStatsCard,
    ClientEditTabInformation,
    ContractsList,
    QuotationsList,
    EvaluationRequestsList,
    AddressAccountInfo,
    ContactAccountInfo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logoFile: null,
    };
  },
  methods: {
    updateClientLogo() {
      if (this.logoFile !== null) {
        const logoData = serialize({ image: this.logoFile });

        this.$store
          .dispatch('app-client/updateLogoImage', {
            id: this.clientData.id,
            logoData,
          })
          .then((response) => {
            console.log(response);
            this.$toast.success(response.data.message);
            // Update the logo path in your local data or perform any other necessary action
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 422) {
                this.$toast.error(err.response.data.message);
                const { message, errors } = err.response.data;
                if (errors) {
                  const errorMessage = {
                    message: '',
                    type: 'danger',
                  };
                  Object.values(errors).forEach((error) => {
                    errorMessage.message += `${error[0]}<br>`;
                  });
                  this.displayMessage(errorMessage);
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    text: message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              } else {
                this.$toast.error(err.response.data.error);
              }
            }
            console.log(err);
          });
      }
    },
  },

  setup() {
    const clientData = ref(null);
    const addressData = ref(null);
    const contactData = ref(null);
    const oldAddressData = ref(null);
    const oldContactData = ref(null);
    const oldClientData = ref(null);

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client';

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
    const resetClientData = () => {
      if (oldClientData.value !== null) {
        clientData.value = JSON.parse(JSON.stringify(oldClientData.value));
      }
    };
    const resetContactData = () => {
      if (oldContactData.value !== null) {
        contactData.value = JSON.parse(JSON.stringify(oldContactData.value));
      }
    };
    const resetAddressData = () => {
      if (oldAddressData.value !== null) {
        addressData.value = JSON.parse(JSON.stringify(oldAddressData.value));
      }
    };
    store
      .dispatch('app-client/fetchClient', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: client } = response.data;
        clientData.value = client;
        if (!client.address) {
          addressData.value = {
            addressable_id: client.id,
          };
        } else {
          addressData.value = client.address;
        }
        
        if (!client.contacts) {
          contactData.value = [{}];
        } else {
          contactData.value = client.contacts;
        }
        oldClientData.value = JSON.parse(JSON.stringify(client));
        oldContactData.value = JSON.parse(JSON.stringify(contactData.value));
        oldAddressData.value = JSON.parse(JSON.stringify(addressData.value));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          clientData.value = undefined;
        }
      });
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      clientData,
      resetClientData,
      resetContactData,
      resetAddressData,
      oldClientData,
      addressData,
      contactData,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
