<template>
  <div>
    <!-- Form: Client Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: building_number -->
          <validation-provider
            #default="validationContext"
            vid="building_number"
            name="building_number"
            
          >
            <b-form-group
              label="رقم المبنى"
              label-for="building_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="addressData.building_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: street -->
          <validation-provider
            #default="validationContext"
            vid="street"
            name="street"
            
          >
            <b-form-group
              label="الشارع"
              label-for="street"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="street"
                v-model="addressData.street"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: city -->
          <validation-provider
            #default="validationContext"
            vid="city"
            name="city"
            
          >
            <b-form-group
              label="المدينة"
              label-for="city"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="addressData.city_id"
                dir="rtl"
                :options="cities"
                :reduce="val => val.value"
                :clearable="false"
                input-id="city"
                @input="cityChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: district_id -->
          <validation-provider
            #default="validationContext"
            vid="district_id"
            name="district_id"
            
          >
            <b-form-group
              label="الحي"
              label-for="district_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="addressData.district_id"
                dir="rtl"
                :loading="isLoading"
                :options="districts"
                :reduce="val => val.value"
                :disabled="districts.length == 0"
                :clearable="false"
                input-id="district_id"
                @input="districtChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: postcode -->
          <validation-provider
            #default="validationContext"
            vid="postcode"
            name="postcode"
            
          >
            <b-form-group
              label="الرمز البريدي"
              label-for="postcode"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="postcode"
                v-model="addressData.postcode"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: additional_number -->
          <validation-provider
            #default="validationContext"
            vid="additional_number"
            name="additional_number"
            
          >
            <b-form-group
              label="الرمز الاضافي"
              label-for="additional_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="additional_number"
                v-model="addressData.additional_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: short_national_address -->
          <validation-provider
            #default="validationContext"
            vid="short_national_address"
            name="short_national_address"
            
          >
            <b-form-group
              label="العنوان الوطني المختصر"
              label-for="short_national_address"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="short_national_address"
                v-model="addressData.short_national_address"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateAddress"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="resetData()"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import clientStore from '../../../clientStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    addressData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    resetData() {
      this.$emit('reset-address-data');
    },
    cityChanged(city_id) {
      this.fetchDistricts(city_id);
      this.addressData.city_id = city_id;
      this.addressData.district_id = null;
    },
    districtChanged(district_id) {
      this.addressData.district_id = district_id;
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('client/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    updateAddress() {
      this.$store
        .dispatch('app-client/updateAddress', { addressData: this.addressData, id: this.addressData.addressable_id })
        .then((response) => {
          this.$toast.success(response.data.message);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },

  setup(props) {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });
    const oldAddressData = JSON.parse(JSON.stringify(props.addressData));
    const resetAddressData = () => {
      addressData.value = JSON.parse(JSON.stringify(oldAddressData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAddressData);
    const types = ref([]);
    store
      .dispatch('app-client/fetchClientOption', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { clientTypes } = response.data;
        types.value = clientTypes;
      });
    const cities = ref([]);
    store
      .dispatch('client/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      });

    const districts = ref([]);
    store
      .dispatch('client/fetchDistricts', { id: props.addressData.city_id })
      .then((response) => {
        const { data } = response.data;
        districts.value = data.map((district) => ({
          label: district.name_ar,
          value: district.id,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    return {
      cities,
      districts,
      refFormObserver,
      types,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
