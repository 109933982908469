<template>
  <div>
    <!-- Header: Contact -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        بيانات مسؤول الاتصال
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-sm"
          variant="primary"
          @click="addItem"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>إضافة</span>
        </b-button>
      </h4>
    </div>
    <!-- Form: Client Info Form -->
    <b-form class="mt-1">
      <b-row
        v-for="(item, index) in contactData"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="إسم المسؤول *"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="item.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <!-- Field: email -->
          <validation-provider
            #default="validationContext"
            vid="email"
            name="email"
            rules="required"
          >
            <b-form-group
              label="البريد الإلكتروني *"
              label-for="email"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="email"
                v-model="item.email"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <!-- Field: mobile -->
          <validation-provider
            #default="validationContext"
            vid="mobile"
            name="mobile"
            rules="required"
          >
            <b-form-group
              label="الجوال *"
              label-for="mobile"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="mobile"
                v-model="item.mobile"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Remove Button -->
        <b-col
          cols="12"
          md="3"
          lg="3"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>حدف</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateContact"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="resetData()"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import Ripple from 'vue-ripple-directive';
import clientStore from '../../../clientStore';

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    contactData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    resetData() {
      this.$emit('reset-contact-data');
    },
    addItem() {
      this.contactData.push({
      });
    },
    removeItem(index) {
      this.contactData.splice(index, 1);
    },
    updateContact() {
      this.$store
        .dispatch('app-client/updateContact', { contactData: this.contactData, id: router.currentRoute.params.id })
        .then((response) => {
          this.$toast.success(response.data.message);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },

  setup(props) {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });
    const oldContactData = JSON.parse(JSON.stringify(props.contactData));
    const resetContactData = () => {
      contactData.value = JSON.parse(JSON.stringify(oldContactData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetContactData);
    const types = ref([]);
    store
      .dispatch('app-client/fetchClientOption', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { clientTypes } = response.data;
        types.value = clientTypes;
      });

    return {
      refFormObserver,
      types,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
