<template>
  <b-row class="match-height">
    <b-col lg="4">
      <card-analytic-goal-overview
        v-if="totalContracts > 0"
        :card-data="contractsData"
      />
      <b-card
        v-else
        title="العقود"
        class="mb-4"
      >
        <b-card-text>
          لا يوجد عقود لعرض الإحصائيات في الوقت الحالي
        </b-card-text>
      </b-card>
    </b-col>
    <b-col lg="4">
      <!-- <apex-donut-chart /> -->
      <card-analytic-goal-overview
        v-if="totalQuotations > 0"
        :card-data="quotationsData"
      />
      <b-card
        v-else
        title="عروض الأسعار"
        class="mb-4"
      >
        <b-card-text>
          لا يوجد عروض أسعار لعرض الإحصائيات في الوقت الحالي
        </b-card-text>
      </b-card>
    </b-col>
    <b-col lg="4">
      <card-analytic-goal-overview
        v-if="totalEvaluationRequests > 0"
        :card-data="evaluationRequestsData"
      />
      <b-card
        v-else
        title="طلبات التقييم"
        class="mb-4"
      >
        <b-card-text>
          لا يوجد طلبات تقييم لعرض الإحصائيات في الوقت الحالي
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CardAnalyticGoalOverview from '@core/components/cards/CardAnalyticGoalOverview.vue';
import ApexDonutChart from '@core/components/charts/apex-chart/ApexDonutChart.vue';
import clientStore from '../../../clientStore';

export default {
  components: {
    CardAnalyticGoalOverview,
    ApexDonutChart,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      evaluationsData: {
        title: 'تقييمات',
        series: [35, 15, 20, 20, 10],
        chart: 'donut',
        data: [
          {
            label: 'مفتوح',
            value: 35,
          },
          {
            label: 'تحت التّقييم',
            value: 15,
          },
          {
            label: 'تحت الإعتماد',
            value: 20,
          },
          {
            label: 'تم الإعتماد',
            value: 20,
          },
        ],
      },
    };
  },
  methods: {
  },
  setup() {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });

    const contractsData = ref(null);
    const quotationsData = ref(null);
    const totalContracts = ref(0);
    const evaluationRequestsData = ref(null);
    const totalEvaluationRequests = ref(0);
    const totalQuotations = ref(0);
    store.dispatch('client/fetchClientStats', { id: router.currentRoute.params.id }).then((res) => {
      console.log(res);
      totalContracts.value = res.data.totalContracts;
      let labels = res.data.contracts.map((item) => item.label);
      let chartOptions = {
        title: 'العقود',
        chart: 'donut',
        hideLegend: true,
        series: res.data.contracts.map((item) => item.count),
        chartOptions: {
          labels,
        },
        data: res.data.contracts.map((item) => ({
          label: item.label,
          value: item.count,
        })),
      };

      contractsData.value = chartOptions;

      // Quotations
      totalQuotations.value = res.data.totalQuotations;
      labels = res.data.quotations.map((item) => item.label);
      chartOptions = {
        title: 'عروض الأسعار',
        chart: 'donut',
        series: res.data.quotations.map((item) => item.count),
        chartOptions: {
          labels,
        },
        data: res.data.quotations.map((item) => ({
          label: item.label,
          value: item.count,
        })),
      };

      quotationsData.value = chartOptions;

      // Quotations
      totalEvaluationRequests.value = res.data.totalEvaluationRequests;
      labels = res.data.evaluationRequests.map((item) => item.label);
      chartOptions = {
        title: 'عروض الأسعار',
        chart: 'donut',
        series: res.data.evaluationRequests.map((item) => item.count),
        hideLegend: true,
        chartOptions: {
          labels,
        },
        data: res.data.evaluationRequests.map((item) => ({
          label: item.label,
          value: item.count,
        })),
      };

      evaluationRequestsData.value = chartOptions;
    });

    return {
      contractsData,
      evaluationRequestsData,
      quotationsData,
      totalContracts,
      totalQuotations,
      totalEvaluationRequests,
    };
  },
};
</script>
