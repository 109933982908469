<template>
  <div>
    <!-- Form: Client Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="إسم العميل"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="clientData.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: type -->
          <validation-provider
            #default="validationContext"
            vid="type"
            name="type"
            rules="required"
          >
            <b-form-group
              label="نوع العميل"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="clientData.type"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
                @input="typeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: identity_card -->
          <validation-provider
            #default="validationContext"
            vid="identity_card"
            name="identity_card"
            rules="required"
          >
            <b-form-group
              v-if="clientData.type === 'individuals' "
              label="رقم الهوية الوطنية *"
              label-for="identity_card"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="identity-card"
                v-model="clientData.identity_card"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: mobile -->
          <validation-provider
            #default="validationContext"
            vid="mobile"
            name="mobile"
            rules="required"
          >
            <b-form-group
              label="رقم الجوال"
              label-for="mobile"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="mobile"
                v-model="clientData.mobile"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: email -->
          <validation-provider
            #default="validationContext"
            vid="email"
            name="email"
            rules="required"
          >
            <b-form-group
              label="البريد الإلكتروني"
              label-for="email"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="email"
                v-model="clientData.email"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: commercial_register -->
          <validation-provider
            v-if="clientData.type !== 'individuals'"
            #default="validationContext"
            vid="commercial_register"
            name="commercial_register"
            rules="required"
          >
            <b-form-group
              label="السجل التجاري"
              label-for="commercial_register"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="commercial-register"
                v-model="clientData.commercial_register"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateClient"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="resetData()"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import clientStore from '../clientStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [alertMessageMixin],
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    resetData() {
      this.$emit('reset-client-data');
    },
    typeChanged(type) {
      this.clientData.type = type;
    },
    updateClient() {
      this.$store
        .dispatch('app-client/updateClient', {
          clientData: this.clientData,
          id: this.clientData.id,
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/client').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },

  setup(props) {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });
    const oldClientData = JSON.parse(JSON.stringify(props.clientData));
    const resetClientData = () => {
      this.clientData.value = JSON.parse(JSON.stringify(oldClientData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetClientData);
    const types = ref([]);
    store
      .dispatch('app-client/fetchClientOption', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { clientTypes } = response.data;
        types.value = clientTypes;
      });

    return {
      refFormObserver,
      types,
      getValidationState,
      resetForm,
      oldClientData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
